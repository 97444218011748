var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c("v-alert", { attrs: { prominent: "", type: "info", text: "" } }, [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Instance Creation with Latest Shared Content"),
              ]),
              _vm._v(
                " The instance(s) you will create will contain the most recent contents that you have shared so far (if any). "
              ),
            ]),
          ]),
          _c(
            "v-tabs",
            {
              attrs: { color: "primary" },
              model: {
                value: _vm.instanceInvitationMode,
                callback: function ($$v) {
                  _vm.instanceInvitationMode = $$v
                },
                expression: "instanceInvitationMode",
              },
            },
            [
              !_vm.isSpaceArchived
                ? _c("v-tab", [_vm._v("Invite via Link")])
                : _vm._e(),
              _c("v-tab", [_vm._v("Invite via Email")]),
              !_vm.isSpaceArchived
                ? _c("v-tab-item", [
                    !_vm.invalidGenerator
                      ? _c(
                          "div",
                          { staticClass: "mt-10" },
                          [
                            _vm.instanceInvitationMode ===
                            _vm.instanceInvitationTabOptions.INVITE_VIA_LINK
                              ? _c("v-form", [
                                  _c(
                                    "h3",
                                    { staticClass: "secondary--text mt-4" },
                                    [
                                      _vm._v(
                                        "Share the following link (e.g. via a Learning Management System)"
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "my-2" }, [
                                    _vm._v(
                                      "Participants will be able to sign up using the below link and receive their own separate instance for work"
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-center" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          value: _vm.firstInvGen,
                                          readonly: true,
                                          outlined: "",
                                          dense: "",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "mt-1 ml-2" },
                                        [
                                          _c("copy-to-clipboard", {
                                            attrs: {
                                              textToCopy: _vm.firstInvGen,
                                              buttonClass:
                                                "caption font-weight-bold",
                                              buttonColor: "primary",
                                              buttonName: "copy to clipboard",
                                              iconButton: true,
                                              isSmall: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "v-banner",
                              {
                                staticClass: "mt-5",
                                attrs: { "two-line": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "actions",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                loading: _vm.creatingGenerator,
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click:
                                                  _vm.createInvitationGenerator,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "create invitation generator"
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  519333973
                                ),
                              },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    attrs: {
                                      slot: "icon",
                                      color: "white",
                                      size: "40",
                                    },
                                    slot: "icon",
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          "x-large": "",
                                          icon: "info",
                                          color: "info",
                                        },
                                      },
                                      [_vm._v("info")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "subtitle-1" }, [
                                  _vm._v(
                                    "No valid invitaiton generator were found. Please create a new generator to invite users."
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
              _c("v-tab-item", [
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        model: {
                          value: _vm.validMultipleInstances,
                          callback: function ($$v) {
                            _vm.validMultipleInstances = $$v
                          },
                          expression: "validMultipleInstances",
                        },
                      },
                      [
                        _c("v-textarea", {
                          attrs: {
                            required: "",
                            rows: "3",
                            "auto-grow": "",
                            disabled: _vm.inviting,
                            outlined: "",
                          },
                          on: { input: _vm.updateEmailString },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [_vm._v("Enter user emails")]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.emailsInput,
                            callback: function ($$v) {
                              _vm.emailsInput = $$v
                            },
                            expression: "emailsInput",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-dialog",
                      {
                        attrs: { persistent: "", "max-width": "500" },
                        model: {
                          value: _vm.showEmailWarning,
                          callback: function ($$v) {
                            _vm.showEmailWarning = $$v
                          },
                          expression: "showEmailWarning",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _c(
                                "span",
                                { staticClass: "title secondary--text" },
                                [_vm._v("Too many recipients")]
                              ),
                            ]),
                            _c("v-card-text", [
                              _c("p", [
                                _vm._v(
                                  "You might want to consider inviting this many users via the invitation link instead:"
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.firstInvGen,
                                      readonly: true,
                                      outlined: "",
                                    },
                                  }),
                                  _c("CopyToClipboard", {
                                    attrs: {
                                      textToCopy: _vm.firstInvGen,
                                      buttonClass: "caption font-weight-bold",
                                      buttonColor: "primary",
                                      isTextButton: true,
                                      buttonName: "copy to clipboard",
                                      iconButton: true,
                                      isSmall: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "" },
                                    on: { click: _vm.closeDialog },
                                  },
                                  [_vm._v("Don't invite")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: { click: _vm.skipDialog },
                                  },
                                  [_vm._v("Invite anyway")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.emailsInput.length
                      ? _c("div", { staticClass: "mt-3 secondary--text" }, [
                          _c("h3", [
                            _vm._v(
                              "A separate instance will be created for each of the following users who will receive an invitation email to join:"
                            ),
                          ]),
                          _c(
                            "div",
                            _vm._l(_vm.emails, function (email, index) {
                              return _c(
                                "ul",
                                { key: index, staticClass: "mt-3" },
                                [
                                  email.status ===
                                    _vm.emailValidationOutcomes
                                      .LOW_RISK_EMAIL ||
                                  email.status ===
                                    _vm.emailValidationOutcomes
                                      .UNKNOWN_RISK_EMAIL
                                    ? _c(
                                        "li",
                                        [
                                          _vm._v(
                                            " " + _vm._s(email.email) + " "
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "x-small": "",
                                                color: "success",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("check_circle")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-uppercase font-weight-bold",
                                                },
                                                [_vm._v("validated")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : email.status ===
                                      _vm.emailValidationOutcomes
                                        .HIGH_RISK_EMAIL
                                    ? _c(
                                        "li",
                                        [
                                          _vm._v(
                                            " " + _vm._s(email.email) + " "
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "x-small": "",
                                                color: "error",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("cancel")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-uppercase font-weight-bold",
                                                },
                                                [_vm._v("validation failed")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : email.status ===
                                      _vm.emailValidationOutcomes
                                        .UNVALIDATED_EMAIL
                                    ? _c(
                                        "li",
                                        [
                                          _vm._v(
                                            " " + _vm._s(email.email) + " "
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "x-small": "",
                                                color: "secondary",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("warning")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-uppercase font-weight-bold",
                                                },
                                                [_vm._v("validation error")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.validatingEmails
                                    ? _c(
                                        "li",
                                        [
                                          _vm._v(
                                            " " + _vm._s(email.email) + " "
                                          ),
                                          _c("v-progress-circular", {
                                            staticClass: "ml-1",
                                            attrs: {
                                              size: 20,
                                              indeterminate: "",
                                              color: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c("li", [_vm._v(_vm._s(email.email))]),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("InvitationErrorsDialog", {
            attrs: {
              showDialog: _vm.showInvitationErrorsDialog,
              errorType: _vm.validationErrorType,
              invalidEmails: _vm.invalidEmailsList,
            },
            on: {
              proceedWithTheInvitation: function ($event) {
                return _vm.inviteUsers($event.value)
              },
              inviteWithoutFetchingDistributed: function ($event) {
                return _vm.createInstances(null, $event.value)
              },
              fetchAndInviteAgain: function ($event) {
                return _vm.inviteUsers($event.value)
              },
            },
          }),
        ],
        1
      ),
      _vm.instanceInvitationMode !==
      _vm.instanceInvitationTabOptions.INVITE_VIA_LINK
        ? _c("v-card-actions", [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-center w-100" },
              [
                _vm.$store.state.userInfo.is_sysadmin
                  ? _c("v-checkbox", {
                      staticClass: "mb-1 mt-5 pa-0",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _vm._v(
                                      " Generate invitations but don't send emails "
                                    ),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ml-1",
                                        attrs: {
                                          color: "info",
                                          label: "",
                                          outlined: "",
                                          "x-small": "",
                                        },
                                      },
                                      [_vm._v("Sysadmin")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1335303574
                      ),
                      model: {
                        value: _vm.generateInvitationLinksOnly,
                        callback: function ($$v) {
                          _vm.generateInvitationLinksOnly = $$v
                        },
                        expression: "generateInvitationLinksOnly",
                      },
                    })
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      loading: _vm.inviting || _vm.validatingEmails,
                      disabled:
                        !_vm.validMultipleInstances ||
                        _vm.validatingEmails ||
                        _vm.inviting ||
                        !_vm.emailsInput,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.validateEmailsAndInvite()
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                    _vm._v(" invite "),
                  ],
                  1
                ),
                _vm.error
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mt-4 w-100",
                        attrs: { color: "error", icon: "warning", text: "" },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Invitation Failure Error"),
                          ]),
                          _vm._v(
                            " An error has occurred while inviting the users. Please try again and if the problem persists contact support@alphacruncher.com "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }